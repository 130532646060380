<template>
  <div
    ref="datePicker"
    class="f-date-picker"
    :class="{
      'is-full-width': isFullWidth,
    }"
  >
    <AppText v-if="label" class="f-input__label">
      {{ label }}
    </AppText>
    <ElDatePicker
      v-bind="$attrs"
      :class="{
        'is-small': isSmall,
      }"
      :prefix-icon="customPrefix"
      :format="dateFormat"
      :value-format="dateFormat"
      :popper-options="{
        modifiers: [{
          name: 'offset',
          options: {
            offset: [0, 4],
          },
        }],
      }"
    />
  </div>
</template>

<script setup>
import 'element-plus/theme-chalk/el-date-picker.css';
import { ElDatePicker } from 'element-plus';
import {
  h, onMounted, ref, shallowRef,
} from 'vue';

import AppIcon from '@/components/Icon/AppIcon.vue';

import { useProfile } from '@/composables/useProfile';

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  isFullWidth: {
    type: Boolean,
    default: true,
  },
  isSmall: {
    type: Boolean,
    default: false,
  },
  isInputReadonly: {
    type: Boolean,
    default: false,
  },
});

const customPrefix = shallowRef({
  render() {
    return h(AppIcon, {
      name: 'calendar',
      size: '18px',
    });
  },
});

const datePicker = ref(null);
onMounted(() => {
  const inputComponent = datePicker.value.querySelector('.el-input__inner');
  if (props.isInputReadonly && inputComponent) {
    inputComponent.setAttribute('readonly', true);
  }
});

const { dateFormat } = useProfile();
</script>

<style scoped lang="scss">
@import "styles";

.f-date-picker{
  &.is-full-width {
    width: 100%;
    &:deep(.el-input) {
      width: 100%;
    }
  }

  &:deep(.el-input__wrapper) {
    @include height;
  }

  &:deep(.el-input__inner) {
    padding: 0 4px !important;
    @include height;
    @include form-element;
  }

  :deep(.is-small) {
    .el-input__inner {
      min-height: 36px;
      height: 36px;
    }
  }
}

</style>
