<template>
  <div class="period-selector">
    <FDropdown
      show-indicator
      block-class="select-columns-popper"
      placement="bottom"
      :teleported="false"
    >
      <template #title>
        {{ currentMonth }}
      </template>
      <template #content>
        <MonthSelector :months="processedMonths" @set-month="onSetMonth" />
      </template>
    </FDropdown>
    <FDropdown
      show-indicator
      block-class="select-columns-popper"
      placement="bottom"
      :teleported="false"
    >
      <template #title>
        {{ params.year || currentYear }}
      </template>
      <template #content>
        <DropdownItem
          v-for="(item, index) in processedYears"
          :key="index"
          no-border
          @click="onSetYear(item)"
        >
          {{ item }}
        </DropdownItem>
      </template>
    </FDropdown>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import dayjs from 'dayjs';

import DropdownItem from '@/components/ElementUI/DropdownItem.vue';

import { months, years } from '@/common/data';
import { useProfile } from '@/composables/useProfile';
import { useLang } from '@/composables/useLang';
import { i18n } from '@/plugins/localization';

import MonthSelector from './MonthSelector.vue';

const props = defineProps({
  params: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(['change']);

const { t } = i18n.global;

const date = defineModel({ type: String, default: '' });
const { language } = useLang();

const { registeredDate } = useProfile();
const registeredMonth = computed(() => dayjs(registeredDate.value).month());

const isNotAsianLanguages = computed(() => ['ru', 'en'].includes(language.value));

const currentYear = computed(() => dayjs(date.value || undefined).year());
const todayYear = computed(() => dayjs().year());
const todayMonth = computed(() => dayjs().month());

const processedMonths = computed(() => months.map((month) => {
  const name = t(`month.${month.name}`);

  let isAvailable = true;
  if (currentYear.value < todayYear.value) {
    isAvailable = registeredMonth.value <= month.value;
  } else if (currentYear.value === todayYear.value) {
    isAvailable = todayMonth.value >= month.value;
  }
  return {
    ...month,
    name,
    text: isNotAsianLanguages.value ? name.substring(0, 3) : name,
    isAvailable,
  };
}));

const processedYears = computed(() => years.filter((year) => year >= dayjs(registeredDate.value).year() && year <= dayjs().year()));

const monthNames = months.map((m) => m.name);
const currentMonth = computed(() => props.params.month || monthNames[dayjs(date.value || undefined).month()]);

const onSetMonth = (m) => {
  date.value = dayjs(date.value || undefined).month(m).format();
  emit('change');
};

const onSetYear = (y) => {
  date.value = dayjs(date.value || undefined).year(y).format();
  emit('change');
};
</script>

<style scoped lang="scss">
.period-selector {
  width: 170px;
  background-color: #fff;
  position: absolute;
  bottom: 288px;
  left: 68px;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

:deep(.f-dropdown--title) {
  padding: 6px 4px 3px 9px !important;
  font-size: 16px;
}
</style>
