<template>
  <div class="range-wrapper">
    <div class="range">
      <FInput
        v-model="fromDate"
        has-prefix
        mask="date"
        :placeholder="t('common.from')"
        :has-error="Boolean(fromDate.length && !isFromDateValid)"
        :error-text="fromDateErrorMessage"
        @keydown.enter.stop="onInputEnter"
      >
        <template #prefix>
          <AppIcon name="calendar" size="18px" fill="var(--color-black-04)" />
        </template>
        <template #suffix>
          <FButton
            v-if="fromDate"
            is-icon
            type="transparent"
            small
            icon="x-close-small"
            class="close-button"
            @click="onCloseButtonClick(0)"
          />
        </template>
      </FInput>
      <PeriodSelector
        v-if="isPickerOpen && propRange"
        v-model="propRange[0]"
        :params="startParams"
        @change="onPeriodSelectorChange"
      />

      <span class="range__item">&mdash;</span>

      <FInput
        v-model="toDate"
        has-prefix
        mask="date"
        :placeholder="t('common.to')"
        :has-error="Boolean(toDate.length && !isToDateValid)"
        :error-text="toDateErrorMessage"
        @keydown.enter.stop="onInputEnter"
      >
        <template #prefix>
          <AppIcon name="calendar" size="18px" fill="var(--color-black-04)" />
        </template>
        <template #suffix>
          <FButton
            v-if="toDate"
            is-icon
            type="transparent"
            small
            icon="x-close-small"
            class="close-button"
            @click="onCloseButtonClick(1)"
          />
        </template>
      </FInput>
      <PeriodSelector
        v-if="isPickerOpen && propRange"
        v-model="propRange[1]"
        :params="endParams"
        style="left: 380px;"
        @change="onPeriodSelectorChange"
      />
    </div>

    <ElDatePicker
      ref="datePicker"
      :model-value="propRange"
      type="daterange"
      range-separator="&mdash;"
      start-placeholder="From"
      unlink-panels
      end-placeholder="To"
      popper-class="date-daterange-popper"
      :format="dateFormat"
      :teleported="false"
      :disabled-date="getDisabledDates"
      @calendar-change="calendarChange"
      @panel-change="onPanelChange"
    />
  </div>
</template>

<script>
import { ElDatePicker } from 'element-plus';
import {
  computed, defineComponent, ref, watch,
} from 'vue';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { coloredLog } from '@/utils/coloredLog';
import { useModel } from '@/composables/useModel';
import { useProfile } from '@/composables/useProfile';
import { i18n } from '@/plugins/localization';

import PeriodSelector from './PeriodSelector.vue';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const day = ({ date, timezoneCode, dateFormat }) => (timezoneCode.value
  ? dayjs(date).tz(timezoneCode.value).format(dateFormat.value)
  : dayjs(date).format(dateFormat.value));

export default defineComponent({
  components: {
    ElDatePicker,
    PeriodSelector,
  },
  props: {
    range: {
      type: Array,
      default: () => [],
    },
    isPickerOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:are-dates-valid', 'update:range', 'update:isPickerOpen'],
  setup(props, { emit, expose }) {
    const {
      dateFormat,
      startDatePeriodDate,
      timezoneCode,
    } = useProfile();
    const { t } = i18n.global;

    const { value: propRange } = useModel(props, emit, { prop: 'range' });
    const { value: propIsPickerOpen } = useModel(props, emit, { prop: 'isPickerOpen' });

    const fromDate = ref('');
    const toDate = ref('');

    const regDate = day({ date: startDatePeriodDate.value, timezoneCode, dateFormat });
    const todayDate = day({ timezoneCode, dateFormat });

    const getDisabledDates = (time) => {
      const currentDate = dayjs(dayjs(time).format(dateFormat.value), dateFormat.value).startOf('day');

      const isMoreThanRegistrationDate = currentDate.diff(dayjs(regDate, dateFormat.value).startOf('day'), 'day') >= 0;
      const isLessThanToday = currentDate.diff(dayjs(todayDate, dateFormat.value).startOf('day'), 'day') <= 0;

      return !(isMoreThanRegistrationDate && isLessThanToday);
    };

    const fromDateDataValid = computed(() => dayjs(fromDate.value, dateFormat.value, true).isValid());
    const fromDateMinPeriodValid = computed(() => dayjs(fromDate.value, dateFormat.value, true).diff(dayjs(regDate, dateFormat.value), 'day') >= 0);
    const fromDateMaxPeriodValid = computed(() => dayjs(fromDate.value, dateFormat.value, true).diff(dayjs(), 'day') <= 0);
    const fromDateNotBiggerThanTo = computed(() => {
      if (toDate.value) {
        return dayjs(fromDate.value, dateFormat.value, true).diff(dayjs(toDate.value, dateFormat.value, true), 'day') <= 0;
      }
      return true;
    });
    const isFromDateValid = computed(() => fromDateDataValid.value && fromDateMinPeriodValid.value && fromDateMaxPeriodValid.value && fromDateNotBiggerThanTo.value);
    const fromDateErrorMessage = computed(() => {
      if (!fromDateDataValid.value) {
        return t('component.dateRangePicker.enterDateWithFormat', { value: dateFormat.value });
      }
      if (!fromDateMinPeriodValid.value) {
        return t('component.dateRangePicker.cannotBeOlderThanCreationDate');
      }
      if (!fromDateMaxPeriodValid.value) {
        return t('component.dateRangePicker.cannotBeInFuture');
      }
      if (!fromDateNotBiggerThanTo.value) {
        return t('component.dateRangePicker.cannotBiggerThanEnd');
      }
      return '';
    });

    const toDateDataValid = computed(() => dayjs(toDate.value, dateFormat.value, true).isValid());
    const toDateMinPeriodValid = computed(() => dayjs(toDate.value, dateFormat.value, true).diff(dayjs(regDate, dateFormat.value), 'day') >= 0);
    const toDateMaxPeriodValid = computed(() => dayjs(toDate.value, dateFormat.value, true).diff(dayjs(), 'day') <= 0);
    const toDateNotLessThanFrom = computed(() => dayjs(toDate.value, dateFormat.value, true).diff(dayjs(fromDate.value, dateFormat.value, true), 'day') >= 0);
    const isToDateValid = computed(() => toDateDataValid.value && toDateMinPeriodValid && toDateMaxPeriodValid.value && toDateNotLessThanFrom.value);
    const toDateErrorMessage = computed(() => {
      if (!toDateDataValid.value) {
        return t('component.dateRangePicker.enterDateWithFormat', { value: dateFormat.value });
      }
      if (!toDateMinPeriodValid.value) {
        return t('component.dateRangePicker.cannotBeOlderThanCreationDate');
      }
      if (!toDateMaxPeriodValid.value) {
        return t('component.dateRangePicker.cannotBeInFuture');
      }
      if (!toDateNotLessThanFrom.value) {
        return t('component.dateRangePicker.cannotLessThanStart');
      }
      return '';
    });

    const areDatesValid = computed(() => {
      emit('update:are-dates-valid', isFromDateValid.value && isToDateValid.value);

      return isFromDateValid.value && isToDateValid.value;
    });

    const clearPicker = () => {
      propIsPickerOpen.value = false;
      propRange.value = [];
      fromDate.value = '';
      toDate.value = '';
      emit('update:are-dates-valid', areDatesValid.value);
    };

    watch(() => propRange.value, (val) => {
      if (!val) {
        clearPicker();
      }
    });

    const updateDates = () => {
      if (areDatesValid.value) {
        const todate = `${dayjs(toDate.value, dateFormat?.value).format('YYYY-MM-DD')}T23:59:59`;
        propRange.value = [
          dayjs(fromDate.value, dateFormat?.value).format('YYYY-MM-DDTHH:mm:ss'),
          todate,
        ];
        onPanelChange();
      }
    };

    const initDates = () => {
      if (!propRange.value?.length && propIsPickerOpen.value) {
        fromDate.value = regDate;
        toDate.value = todayDate;
        updateDates();
      }
    };

    watch(propIsPickerOpen, () => {
      initDates();
      updateTodayDate();
      if (!propRange.value) {
        propRange.value = [];
      }
      onPanelChange();
    });

    const onInputEnter = () => {
      checkSwappedDates();
      if (areDatesValid.value) {
        updateDates();
      }
    };

    const datePicker = ref(null);
    const startParams = ref({});
    const endParams = ref({});

    const syncCalendarAndCustomPeriodPicker = () => {
      const start = document.querySelector('.el-picker-panel__body .is-left .el-date-range-picker__header div');
      const end = document.querySelector('.el-picker-panel__body .is-right .el-date-range-picker__header div');

      if (start && end) {
        startParams.value.month = start.innerText.replace(/\d+/g, '').trim();
        startParams.value.year = Number(start.innerText.match(/(\d+)/)[0]);

        endParams.value.month = end.innerText.replace(/\d+/g, '').trim();
        endParams.value.year = Number(end.innerText.match(/(\d+)/)[0]);
      }
    };

    const getDate = (element) => Number(element.querySelector('.el-date-table-cell__text').innerHTML);
    const replaceTodayDate = (fakeValue, newValue) => {
      fakeValue.classList.remove('today');
      newValue.classList.add('today');
    };

    const updateTodayDate = () => {
      const today = document.querySelector('.el-picker-panel__body .is-left .el-date-table__row .today')
                 || document.querySelector('.el-picker-panel__body .is-right .el-date-table__row .today');
      const todayDay = timezoneCode.value
        ? Number(dayjs().tz(timezoneCode.value).format('DD'))
        : Number(dayjs().format('DD'));

      if (today) {
        if (getDate(today) === todayDay) {
          return;
        }
        if (getDate(today.nextElementSibling) === todayDay) {
          replaceTodayDate(today, today.nextElementSibling);
          return;
        }
        if (getDate(today.previousElementSibling) === todayDay) {
          replaceTodayDate(today, today.previousElementSibling);
          return;
        }

        const parentRow = today.parentElement;
        const dayIndex = Array.from(parentRow.children).findIndex((element) => element.className.split(' ').includes('today'));
        // if fakeToday is first then check last day in previous week
        if (dayIndex === 0) {
          const realDate = parentRow.previousElementSibling.querySelectorAll('td')[6];
          replaceTodayDate(today, realDate);
          return;
        }

        // if fakeToday is last - check first day in next week
        if (dayIndex === 6) {
          const realDate = parentRow.nextElementSibling.querySelectorAll('td')[0];
          replaceTodayDate(today, realDate);
        }
      }
    };

    const onPanelChange = () => {
      setTimeout(() => {
        syncCalendarAndCustomPeriodPicker();
        updateTodayDate();
      }, 0);
    };
    const checkSwappedDates = () => {
      if (!fromDateNotBiggerThanTo.value && !toDateNotLessThanFrom.value) {
        coloredLog('DATES WERE SWAPPED');
        [fromDate.value, toDate.value] = [toDate.value, fromDate.value];
        updateDates();
      }
    };

    const onPeriodSelectorChange = () => {
      fromDate.value = propRange.value[0] ? dayjs(propRange.value[0]).format(dateFormat?.value) : '';
      toDate.value = propRange.value[1] ? dayjs(propRange.value[1]).format(dateFormat?.value) : '';

      startParams.value = {};
      endParams.value = {};
      onPanelChange();
      checkSwappedDates();
    };

    const calendarChange = (val) => {
      fromDate.value = val[0] ? dayjs(val[0]).format(dateFormat?.value) : '';
      toDate.value = val[1] ? dayjs(val[1]).format(dateFormat?.value) : '';
      if (areDatesValid.value) {
        propIsPickerOpen.value = false;
      }

      updateDates();
      onPanelChange();
    };

    const initDataFromVModel = () => {
      if (props.range?.length) {
        calendarChange(props.range);
      }
    };

    const onCloseButtonClick = (itemNumber) => {
      if (itemNumber === 0) {
        fromDate.value = '';
      }
      if (itemNumber === 1) {
        toDate.value = '';
      }
      propRange[itemNumber] = undefined;

      if (fromDate.value === '' && toDate.value === '') {
        clearPicker();
      }
    };

    initDataFromVModel();

    expose({ clearPicker, initDates });

    return {
      datePicker,

      propRange,
      dateFormat,
      calendarChange,

      fromDate,
      toDate,
      isFromDateValid,
      isToDateValid,
      fromDateErrorMessage,
      toDateErrorMessage,

      areDatesValid,
      clearPicker,
      onInputEnter,
      onPeriodSelectorChange,

      getDisabledDates,
      onPanelChange,

      startParams,
      endParams,
      onCloseButtonClick,
    };
  },
});
</script>

<style scoped lang="scss">
.range-wrapper {
  position: relative;
  .range {
    width: 100%;
    // height: 54px;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 0;

    // &__item:first-child, &__item:last-child {
    //   flex-grow: 1;
    //   text-align: center;
    // }

    :deep(.el-input__suffix) {
      .el-button .icon {
        fill: var(--color-black);
      }
    }

    &__item {
      margin: 0 12px;
      display: flex;
      padding-top: 16px;
    }
  }
}

  :deep(.el-date-range-picker__content.is-left) {
    padding: 16px 14px 16px 10px;
  }
  :deep(.el-date-range-picker__content.is-right) {
    padding: 16px 10px 16px 14px;
  }

:deep(.el-range-editor.el-input__inner) {
  display: none;
}

:deep(.el-picker-panel.el-date-range-picker) {
  width: unset;

  .el-picker-panel__icon-btn.arrow-left > .el-icon svg,
  .el-picker-panel__icon-btn.arrow-right > .el-icon svg {
    width: 12px !important;
    height: 12px !important;
    flex-shrink: 0;
  }

  .el-date-range-picker__content.is-left {
    border: none;
  }

  .el-date-range-picker__header {
    font-size: 16px;
    @include font-medium;

    .arrow-left, .arrow-right {
      width: 36px;
      height: 36px;
      margin: 0;
      background: #F2F2F2;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    div {
      height: 36px;
      color: var(--color-black);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .el-date-table th {
    border: none;
    text-transform: uppercase;
  }

  .el-date-table td.end-date .el-date-table-cell__text, .el-date-table td.start-date .el-date-table-cell__text {
    color: var(--color-black);
    background: none;
  }

  .el-date-table-cell__text, .el-date-range-picker__header, th {
    @include font-medium;
  }

  th {
    font-size: 10px;
    letter-spacing: 1.5px;
  }

  td {
    font-size: 12px;
    letter-spacing: 0.22px;
  }

  .el-date-table td.in-range .el-date-table-cell {
    background: #FBF3EC;
    display: flex;
    align-items: center;
    color: var(--color-black);
  }

  .el-date-table td.in-range {
    &:first-child {
      .el-date-table-cell {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        overflow: hidden;
        margin-left: 4px;
      }
    }
    &:last-child {
      .el-date-table-cell {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        overflow: hidden;
        margin-right: 4px;
      }
    }
  }

  .el-date-table td.start-date .el-date-table-cell .el-date-table-cell__text,
  .el-date-table td.end-date .el-date-table-cell .el-date-table-cell__text {
    width: 32px;
    height: 32px;
    border: 2px solid var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .el-date-table td.disabled {
    &.start-date, &.end-date, &.in-range {
      .el-date-table-cell {
        .el-date-table-cell__text {
          color: var(--el-text-color-placeholder);
          border-color: var(--color-error);
        }
      }
    }
    .el-date-table-cell {
      background-color: transparent;
    }
  }
  // .el-date-table td.today {
  //   & + td {
  //     pointer-events: none;
  //     .el-date-table-cell__text  {
  //       cursor: not-allowed;
  //       color: var(--el-text-color-placeholder);
  //       pointer-events: none;
  //       &:hover {
  //         background-color: initial;
  //       }
  //     }
  //   }
  // }
  .el-date-table td.disabled.in-range .el-date-table-cell {
    background-color: var(--el-fill-color-light);
  }
}

:deep(.el-date-editor) {
  display: none;
}

.close-button {
  opacity: 0.4;
  @include transition-base(opacity);
  &:hover {
    opacity: 1;
  }
}
</style>
