<template>
  <ElCollapseItem v-bind="$attrs" class="f-collapse-item" :name="index">
    <template v-for="(el, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </ElCollapseItem>
</template>

<script>
import 'element-plus/theme-chalk/el-collapse-item.css';
import { ElCollapseItem } from 'element-plus';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    ElCollapseItem,
  },
  props: {
    index: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
.f-collapse-item {
  :deep(.el-collapse-item__header) {
    padding: 14px 28px;
    height: auto;
  }
}
</style>
