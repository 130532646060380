<template>
  <div class="month-selector">
    <template v-for="month in months" :key="month.value">
      <div
        class="month-item"
        :class="{
          'is-disabled': !month.isAvailable,
        }"
        @click="onClick(month)"
      >
        {{ month.text }}
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    months: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['set-month'],
  setup(props, { emit }) {
    const onClick = (month) => {
      if (month.isAvailable) {
        emit('set-month', month.value);
      }
    };

    return {
      onClick,
    };
  },
});
</script>

<style scoped lang="scss">
.month-selector {
  padding: 20px;
  display: grid;
  gap: 10px 4px;
  grid-template-columns: 1fr 1fr 1fr;
}

.month-item {
  font-size: 12px;
  @include font-medium;

  width: 46px;
  height: 27px;
  @include flex-center;

  background-color: #ffffff;
  @include transition-base(background-color);
  cursor: pointer;

  &:hover {
    background-color: #F2F2F2;
  }

  &.is-disabled {
    cursor: default;
    color: var(--el-text-color-placeholder);
    &:hover {
      background-color: var(--color-white);
    }
  }
}
</style>
