<template>
  <div v-click-outside="() => isPickerOpen = false" class="picker" tabindex="0" :class="{ active: isPickerOpen }">
    <div
      class="picker__input"
      :class="{
        'is-filled': areDatesValid && propDatesReadonly.length === 2,
      }"
      @click.capture="isPickerOpen = !isPickerOpen"
    >
      <AppIcon
        name="calendar"
        size="18px"
        :opacity="0.4"
        is-img-tag
        style="margin-right: 12px;"
      />
      <AppText
        :line-height="1.35"
        class="picker__input-val"
        :class="{
          'has-value': areDatesValid && propDatesReadonly.length === 2,
        }"
      >
        <template v-if="areDatesValid && propDatesReadonly.length === 2">
          {{ propDatesReadonly[0] }} &mdash; {{ propDatesReadonly[1] }}
        </template>
        <template v-else>
          {{ t('common.allTime') }}
        </template>
      </AppText>
      <div style="position: relative; height: 100%; aspect-ratio: 1/1; transform: translateX(11px);" class="flex-center">
        <ResetButton
          v-if="areDatesValid && propDatesReadonly.length === 2"
          style="position: relative;"
          @reset="clearPicker"
        />
        <DropdownArrow v-else :is-open="isPickerOpen" />
      </div>
    </div>
    <Transition name="show-fast">
      <div v-show="isPickerOpen" class="el-popper dropdown-popper daterange-popper">
        <div class="picker__range dropdown-wrapper">
          <DateRangePicker
            ref="rangePicker"
            v-model:range="range"
            v-model:is-picker-open="isPickerOpen"
            v-model:areDatesValid="areDatesValid"
          />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';

import DropdownArrow from '@/components/Icon/DropdownArrow.vue';
import ResetButton from '@/components/Buttons/ResetButton.vue';

import { useProfile } from '@/composables/useProfile';
import { clickOutside as vClickOutside } from '@/directives/clickOutside';
import { keys, useKeyboardEvent } from '@/composables/useKeyboardEvent';

import DateRangePicker from './DateRangePicker.vue';

dayjs.extend(customParseFormat);

const emit = defineEmits(['update']);

const { dateFormat } = useProfile();

const isPickerOpen = ref(false);
const areDatesValid = ref(false);

useKeyboardEvent(keys.BACKQUOTE, () => { isPickerOpen.value = false; });

const range = defineModel({ type: Array, default: [] });
const propDatesReadonly = computed(() => {
  if (range.value === null) {
    return [];
  }
  return [
    range.value[0] && dayjs(range.value[0]).format(dateFormat?.value),
    range.value[1] && dayjs(range.value[1]).format(dateFormat?.value),
  ].filter((el) => el);
});

const rangePicker = ref(null);
const clearPicker = () => {
  rangePicker.value.clearPicker();
};

watch(range, () => {
  emit('update');
});
</script>

<style lang="scss" scoped>
.picker {
  position: relative;

  @include focus-visible;

  &__range {
    padding: 20px 20px 10px 20px;
  }

  &__input {
    cursor: pointer;
    border: 1px solid var(--color-black-01);
    border-radius: 4px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 12px;
    background-color: #fff;
    color: #999;
    @include transition-base('background-color, color');
    @include prevent-selection;

    &:hover {
      background-color: var(--color-F7F7F7);
      border: 1px solid var(--color-black-02);
    }

    &.is-filled {
      background-color: var(--color-F7F7F7);
    }

    &-val {
      flex: 0 1 100%;
      margin-right: 8px;
    }

    .has-value {
      color: var(--color-black);
    }
  }

  &.active {
    .picker__input {
      border: 1px solid var(--color-primary);
    }
  }

  .daterange-popper {
    position: absolute;
    top: calc(40px + 4px);
    right: 0;
    z-index: 5;
    width: 660px;
  }
}

:global(.date-daterange-popper) {
  display: unset !important;
  position: unset !important;
  top: unset !important;
  left: -10px;
  box-shadow: unset !important;
  border: none !important;
  outline: none !important;
  overflow: unset !important;
  filter: none !important;
}
</style>
